
import axios from 'axios';

import {
  initDayjsPlugins,
  initI18N,
  initTimeLocale,
  GV_MICRO_APP,
  getI18nResources,
  applyRuntimeTheme,
} from '@grandview/shared';

import en from './assets/i18n/pdp-locale-en.json';
import ko from './assets/i18n/pdp-locale-ko.json';

export function getMicroAppConfig(microAppName: GV_MICRO_APP | string, jsonFile = 'config.json') {
  return axios.get<any>(`/environments/${jsonFile}`);
}

Promise.all([
  getMicroAppConfig('pdp'),
  getMicroAppConfig('pdp', 'theme-color-level.json'),
  getMicroAppConfig('pdp', 'theme-custom-component.json'),
  getMicroAppConfig('pdp', 'theme-antd.json'),
  getMicroAppConfig('pdp', 'theme-pdp.json'),
  getMicroAppConfig('pdp', 'environment.json'),
]).then(
  (result: any[]) => {
    // [1] set global values
    (window as any).GV_CONFIG = result[0]?.data;
    const themeColorLevelConfig = result[1]?.data || {};
    const themeCustomComponentConfig = result[2]?.data || {};
    const themeAntDConfig = result[3]?.data || {};
    (window as any).GV_THEME_PORTAL = result[4]?.data || {};
    (window as any).GV_PRODUCTION = result[5]?.data?.production;
    console.log('> [environment] GV_CONFIG:', (window as any).GV_CONFIG);
    console.log('> [environment] GV_PRODUCTION:', (window as any).GV_PRODUCTION);
    console.log('> [environment] GV_THEME_PORTAL:', (window as any).GV_THEME_PORTAL);

    // [2] apply style variables for runtime theme
    applyRuntimeTheme(themeColorLevelConfig, themeAntDConfig, themeCustomComponentConfig, GV_MICRO_APP.PORTAL);

    // [3] load i18n file & date locale
    initI18N(getI18nResources({ en, ko }));
    initTimeLocale();
    initDayjsPlugins();

    import('./bootstrap');
  },
  (error: any) => console.log('> load podo developer portal config error:', error)
);
